.header {
  text-align: center;
  color: #ffffff;
  height: 64px;
  padding-inline: 50px;
  line-height: 64px;
  background-color: #7dbcea;
}

.content {
  text-align: center;
  color: #ffffff;
  line-height: 120px;
  background-color: #3ba0e9;
}

.footer {
  text-align: center;
  color: #ffffff;
  background-color: #7dbcea;
}

.logo-title {
  color: #6415FF;
  vertical-align: text-bottom;
  font-size: 16px;
  font-weight: 700;
}

.logo {
  padding: 12px;
  width: 64px;
  margin-left: 8px;
}

.margin-right {
  margin-right: 16px;
}

.action-wrapper {
  display: flex;
  align-items: center;
}

.recording-started-label {
  white-space: pre-wrap;
}

.guide {
  max-width: 1024px;
}

.guide h2, h3, strong {
  color: #404040;
}

.guide ul li {
  padding-bottom: 8px;
  text-align: justify;
  line-height: 24px;
}

@media screen and (max-width: 600px) {
  .logo-title {
    display: none;
  }

  .logo {
    padding: 10px;
    width: 48px;
    margin-left: 2px;
    margin-top: 8px;
  }

  .recording-started-label {
    width: 110px;
    padding: 4px;
    white-space: pre-wrap;
  }
}

.step-image {
    max-width: 100%;
    height: auto;
}

.step-image-small {
    max-width: 20%;
    height: auto;
}

.sub-section {
  margin-left: 16px;
}

.sub-sub {
  margin-bottom: 0;
}

.guide-section {
  line-height: 24px;
}

.steps-image-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.steps-image-container img {
  max-width: 25%;
  height: auto;
}

.settings-icon {
  color: #6415FF
}

.centerAlert {
  position: fixed;
  top: 0;
  width: 30%;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15)
}

@media screen and (max-width: 1200px) {
  .centerAlert {
    width: 50%;
  }
}

@media screen and (max-width: 900px) {
  .centerAlert {
    width: 60%;
  }
}

@media screen and (max-width: 600px) {
  .steps-image-container {
    display: flex;
    flex-direction: column;
  }

  .steps-image-container img {
    max-width: 65%;
    height: auto;
    display: inline-block;
    margin-bottom: 16px;
  }


  .sub-section {
    margin-left: 8px;
  }

  .step-image-small {
    max-width: 50%;
    height: auto;
}
  .centerAlert {
    width: 70%;
  }
}

@media screen and (max-width: 450px) {
  .logo {
    padding: 10px;
    width: 48px;
    margin-left: 2px;
    margin-top: 8px;
  }

  .settings-icon {
    display: none;
  }

  .step-image-small {
    max-width: 80%;
    height: auto;
}
  .centerAlert {
    width: 80%;
  }
}
